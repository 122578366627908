import React from 'react';
import { Spinner } from '@gogoair/ba-ui-kit';

import './index.css';

const Loading = () => (
  <div className="loading" data-test="loading">
    <h3 className="title">Loading...</h3>
    <Spinner width="80px" height="80px" />
  </div>
);

export default Loading;
