import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card } from '@gogoair/ba-ui-kit';
import Footer from '../../components/Footer';

import './index.css';

const Help = ({ isAuthenticated, isInternal, selectedAccountId }) => (
  <div className="contact" data-test="contact">
    <div className="content">
      <Grid>
        <Card>
          <span data-test="gogo-tech-support">
            <div className="ui-panelrow">
              <h3 className="title">Gogo DASH</h3>
            </div>
            {isAuthenticated ? (
              <React.Fragment>
                {selectedAccountId || isInternal ? (
                  <div className="ui-panelrow">
                    View our <Link to="/notes">Release Notes</Link>
                  </div>
                ) : (
                  <div className="ui-panelrow">
                    You may view release notes here once you have selected an account.
                  </div>
                )}
                <div className="ui-panelrow">
                  For help first try reading through the{' '}
                  <a href="https://business.gogoair.com/support/customer-welcome/dash-training/" target="_blank">
                    Gogo DASH User Guide
                  </a>
                </div>
                <div className="ui-panelrow">
                  For additional assistance please contact{' '}
                  <a
                    href="https://business.gogoair.com/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gogo Business Aviation
                  </a>
                </div>
                <div className="ui-panelrow">
                  For technical resources visit{' '}
                  <a href="https://techpubs.gogoair.com/" target="_blank" rel="noopener noreferrer">
                    Techpubs
                  </a>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="ui-panelrow">
                  Gogo DASH is a powerful addition to your Gogo wireless service.
                </div>
                <div className="ui-panelrow">
                  To sign up for Gogo DASH please contact{' '}
                  <a
                    href="https://business.gogoair.com/getdash/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gogo Business Aviation
                  </a>
                  .
                </div>
              </React.Fragment>
            )}
          </span>
        </Card>
      </Grid>
    </div>
    <Footer />
  </div>
);

export default Help;
