const createAcctMap = (list, appName, searchKey) => {
  const acctMap = {};
  const keys = [];
  list[appName].forEach(acct => {
    acctMap[acct[searchKey]] = acct;
    keys.push(acct[searchKey]);
  });
  return { acctMap, keys };
};

const createIndex = (list, searchKey) => {
  const allApps = {};
  let allKeys = [];
  Object.keys(list).forEach(app => {
    const { acctMap, keys } = createAcctMap(list, app, searchKey);
    allKeys = keys;
    allApps[app] = acctMap;
  });
  return { allApps, allKeys };
};

const indexListBy = (list, searchKey) => {
  const { allApps, allKeys } = createIndex(list, searchKey);
  return [allKeys, allApps];
};

export default indexListBy;
