import { PAGE_REFRESH_LOADING } from '../actions/types';
import { loginAs, logout } from '../actions';

const checkForAuthenticatedUser = store => {
  if (localStorage.getItem('id_token')) {
    const permissionedAccounts = localStorage.getItem('permissionedAccounts');
    const name = localStorage.getItem('name');
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const cob_unverified = /true/i.test(localStorage.getItem('cob_unverified'));
    if (permissionedAccounts && permissionedAccounts !== '[]') {
      store.dispatch({ type: PAGE_REFRESH_LOADING });
      store.dispatch(loginAs(name, JSON.parse(permissionedAccounts), token, email, cob_unverified));
    } else {
      store.dispatch(logout());
    }
  }
};

export default checkForAuthenticatedUser;
