/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';

import NavigationBar from '../../components/NavigationBar';
import { loginAs, logout } from '../../actions';
import { postApi, getUserData } from '../../lib/restUtils';
import logger from '../../logger';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import { setMessage } from '../../actions/messages';
import _variables from './_variables';

import './index.css';
import baLogo from '../../assets/BALogo';

const setupLocalStorage = authResult => {
  const expiresAt = new Date().getTime() + 1000 * authResult.expiresIn;
  const permissionedAccounts = JSON.stringify(authResult['https://dash.gogoair.com/applications']);
  const cobUnverified = authResult.cob_unverified === undefined ? false : authResult.cob_unverified;
  // add user info
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('email', authResult.email);
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('token', authResult.token);
  localStorage.setItem('name', authResult.name);
  localStorage.setItem('email', authResult.email);
  localStorage.setItem('cob_unverified', cobUnverified);
  localStorage.setItem('permissionedAccounts', permissionedAccounts);
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = { username: '', formSubmitting: false };

    this.authenticate = this.authenticate.bind(this);
    this.updateUsername = this.updateUsername.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.logout();
    // Remove everything except savedURL to ensure auth0 doesn't go pear-shaped.
    const savedURL = localStorage.getItem('savedURL');
    localStorage.clear();
    if (savedURL) {
      localStorage.setItem('savedURL', savedURL);
    }
    sessionStorage.clear();
  }

  async authenticate(values) {
    const { username } = values;
    const { password } = values;
    this.setState({
      formSubmitting: true,
    });

    const errorMessage = {
      type: 'alert',
      message: ['The wrong email and/or password was provided'],
      icon: 'fa-comment',
    };
    await postApi('/api/v1/authenticate', {
      username,
      password,
    })
      .then(async resp => {
        const { actions } = this.props;
        if (resp.results) {
          if (resp.results.error) {
            actions.setMessage({
              type: 'alert',
              message: [resp.results.error_description],
              icon: 'fa-comment',
            });
          } else if (resp.results.access_token) {
            const expiresIn = resp.results.expires_in;
            const idToken = resp.results.id_token;

            await getUserData(
              `https://${_variables.domain}/userinfo`,
              resp.results.access_token
            ).then(resp2 => {
              if (resp2) {
                const { results } = resp2;

                console.log('results from Login API call', results);

                const { actions: acts } = this.props;
                if (results) {
                  const accountList = results['https://dash.gogoair.com/applications'];
                  const token = results.sub;
                  if (
                    Object.keys(accountList).length ||
                    accountList['*'] ||
                    results.cob_unverified
                  ) {
                    const { name, email, cob_unverified } = results;
                    setupLocalStorage({ expiresIn, idToken, token, ...results });
                    acts.loginAs(name, accountList, token, email, cob_unverified);
                  } else {
                    actions.setMessage(errorMessage);
                  }
                } else {
                  actions.setMessage(errorMessage);
                }
              } else {
                logger.warn(resp2);
              }
            });
          }
        } else if (resp.status === 504) {
          actions.setMessage({
            type: 'alert',
            message: ['Login Gateway Timeout'],
            icon: 'fa-comment',
          });
        }
      })
      .catch(err => {
        logger.warn(`Error: ${err.error}. Check the console for further details.`);
      })
      .finally(() => {
        if (!localStorage.getItem('name')) {
          this.setState({
            formSubmitting: false,
          });
        }
      });
  }

  updateUsername(e) {
    this.setState({ username: e.target.value });
  }

  render() {
    const {
      match: { path },
    } = this.props;
    const { username, formSubmitting } = this.state;

    return (
      <div className="login">
        <NavigationBar />
        <div className="content">
          <div className="presented-by-banner">Presented By: {baLogo}</div>
          <div className="log-in-wrapper">
            <img className="dashLogo" src="/logo.svg" alt="DASH logo" />

            {path === '/forgotpassword' ? (
              <ForgotPassword username={username} handleUsernameChange={this.updateUsername} />
            ) : (
              <LoginForm
                onSubmit={this.authenticate}
                formSubmitting={formSubmitting}
                handleUsernameChange={this.updateUsername}
              />
            )}
          </div>
        </div>
        <div className="footer">
          Copyright &copy; {new Date().getFullYear()} Gogo Business Aviation LLC. All Rights
          Reserved
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    logout: () => dispatch(logout()),
    loginAs: (name, accountName, token, email, cob_unverified) => {
      dispatch(loginAs(name, accountName, token, email, cob_unverified));
    },
    setMessage: err => dispatch(setMessage(err)),
  },
});

export default connect(null, mapDispatchToProps)(Login);
