import React from 'react';

const logo = (
  <svg width="22px" height="15px" viewBox="0 0 29 22" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Main-dashboard"
        transform="translate(-1058.000000, -29.000000)"
        fill="#40B4E5"
        fillRule="nonzero"
      >
        <g id="home-solid" transform="translate(1058.000000, 29.000000)">
          <path
            d="M14.1171799,5.70919643 L4.86678201,13.1661161 L4.86678201,21.2142857 C4.86678201,21.6482237 5.22619356,22 5.66955017,22 L11.2919377,21.9857589 C11.733726,21.9835969 12.0906976,21.6324531 12.090692,21.2000446 L12.090692,16.5 C12.090692,16.066062 12.4501036,15.7142857 12.8934602,15.7142857 L16.1045329,15.7142857 C16.5478895,15.7142857 16.907301,16.066062 16.907301,16.5 L16.907301,21.1966071 C16.9066343,21.4054166 16.990917,21.6058975 17.1415376,21.753779 C17.2921582,21.9016605 17.4967265,21.9847778 17.7100692,21.9847768 L23.3304498,22 C23.7738064,22 24.133218,21.6482237 24.133218,21.2142857 L24.133218,13.1607143 L14.884827,5.70919643 C14.6608025,5.53245728 14.3412045,5.53245728 14.1171799,5.70919643 Z M28.7290657,10.7775446 L24.5346021,7.39357143 L24.5346021,0.591741071 C24.5346021,0.266287558 24.2650434,0.00245535714 23.932526,0.00245535714 L21.1228374,0.00245535714 C20.7903199,0.00245535714 20.5207612,0.266287558 20.5207612,0.591741071 L20.5207612,4.15741071 L16.0287716,0.540178571 C15.1397423,-0.1758603 13.8572474,-0.1758603 12.968218,0.540178571 L0.267923875,10.7775446 C0.14479563,10.8771519 0.067157566,11.020568 0.05210138,11.1762205 C0.0370451941,11.3318731 0.0858051903,11.4870016 0.187647059,11.6074554 L1.46705882,13.1297768 C1.56862519,13.2506529 1.71514691,13.3270203 1.87430885,13.3420365 C2.0334708,13.3570526 2.19219836,13.3094838 2.31548443,13.2098214 L14.1171799,3.69580357 C14.3412045,3.51906443 14.6608025,3.51906443 14.884827,3.69580357 L26.6870242,13.2098214 C26.8100924,13.3094998 26.968588,13.3572239 27.127619,13.3424876 C27.2866499,13.3277513 27.4331789,13.2517625 27.5349481,13.13125 L28.8143599,11.6089286 C28.9160982,11.4877732 28.9642987,11.3319233 28.94829,11.1758825 C28.9322813,11.0198418 28.8533842,10.8764831 28.7290657,10.7775446 L28.7290657,10.7775446 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default logo;
