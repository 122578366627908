import ggDASHConfig from '../../ggDASHConfig';

const deployedEnvironment = ggDASHConfig.get('deployedEnv');

let domain = 'gogo-ba.auth0.com';
let clientId = 'eg5aTr0IwXRwJGbemiZh1qg99sEK0ug-';

if (deployedEnvironment === 'development') {
  domain = 'gogo-ba.auth0.com';
  clientId = 'eg5aTr0IwXRwJGbemiZh1qg99sEK0ug-';
} else if (deployedEnvironment === 'stage') {
  domain = 'stage-dash.auth0.com';
  clientId = 'jmSRNDWywcy4eDyLb2CFqOBIc8wZ6wKL';
} else if (deployedEnvironment === 'production') {
  domain = 'prod-dash.auth0.com';
  clientId = 'QpHGCPX5o-yHl4UdYsEzHn19hG_xaCOT';
}

const AUTH_CONFIG = {
  domain,
  clientId,
};

export default AUTH_CONFIG;
