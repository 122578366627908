import React from 'react';
import { Switch, Redirect, Link } from 'react-router-dom';
import queryString from 'querystring';
import baLogo from '../../assets/BALogo';
import NavigationBar from '../../components/NavigationBar';
import './index.css';
import Footer from '../../components/Footer';
import ggDASHConfig from '../../ggDASHConfig';
import history from '../../history';
import { getUserData } from '../../lib/restUtils';
import loginVariables from '../Login/_variables';

const Home = () => (
  <div className="home">
    <NavigationBar />
    <div className="container">
      <div className="presented-by-banner">Presented By: {baLogo}</div>
      <div id="auth0_container" className="auth0-container">
        <div className="log-in-wrapper">
          <img className="dashLogo" src="./logo.svg" alt="" />
          <Link className="log-in-button" to="/login">
            Log In
          </Link>
          <div className="sign-up-link">
            To sign up for Gogo DASH please contact{' '}
            <a
              href="https://business.gogoair.com/getdash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gogo Business Aviation
            </a>
            .
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

const setupLocalStorage = authResult => {
  const expiresAt = new Date().getTime() + 1000 * authResult.expires_in;
  const permissionedAccounts = JSON.stringify(authResult['https://dash.gogoair.com/applications']);
  // add user info
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('id_token', authResult.id_token);
  localStorage.setItem('name', authResult.name);
  localStorage.setItem('permissionedAccounts', permissionedAccounts);
};

const checkIfRedirectedFromMainSite = (urlLocation, actions) => {
  if (urlLocation.search) {
    const queryParams = queryString.parse(urlLocation.search.substr(1));
    const { id_token: idToken, access_token: accessToken, expires_in: expiresIn } = queryParams;

    if (idToken && accessToken && expiresIn) {
      getUserData(`https://${loginVariables.domain}/userinfo`, accessToken).then(response => {
        if (response) {
          const { results } = response;
          const {
            name,
            'https://dash.gogoair.com/applications': accountList,
            email,
            cob_unverified,
          } = results;
          setupLocalStorage({ expiresIn, idToken, ...results });
          actions.loginAs(name, accountList, email, cob_unverified);

          history.push('/');
        }
      });
    }
  }
};

// if we're logged in just redirect to the dashboard
export default props => {
  const { isAuthenticated, apps, location, actions } = props;
  let route = <Home />;

  if (isAuthenticated && apps) {
    let pathToApp = '/';
    const groundPortalPath = '/dashboard';
    const appsString = [...apps.values()].join(', ').toLowerCase();

    if (apps.size > 1 || apps.has(ggDASHConfig.apps.all)) {
      pathToApp += ggDASHConfig.apps.sso;
    } else if (appsString.includes(ggDASHConfig.apps.groundportal)) {
      pathToApp = groundPortalPath;
    } else {
      let app = ggDASHConfig.apps[[...apps.values()]?.[0]?.toLowerCase()];
      if (app === 'servicerequest') {
        app = 'service-request';
      }
      pathToApp += app;
    }
    route = <Redirect to={pathToApp} />;
    return <Switch>{route}</Switch>;
  }

  checkIfRedirectedFromMainSite(location, actions);

  return <Switch>{route}</Switch>;
};
