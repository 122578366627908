import { getApiData, postPasswordResetApi } from '../lib/restUtils';

const apiUrl = '/api';
const API_VERSION = 'v1';

/* pull down all aircraft for this account */
// eslint-disable-next-line import/prefer-default-export
export const apiGetInternalAccounts = () => {
  // bend api: http://aircraft.dashdata.dev.gogoair.com/v1/accounts
  const apiURL = `${apiUrl}/${API_VERSION}/accounts`;
  return getApiData(apiURL);
};

/* pull down all aircraft for this account */
export const apiGetAircraft = accountId => {
  // bend api: http://aircraft.dashdata.dev.gogoair.com/v1/aircraft?accountId=123
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/aircraft`;
  // logger.log(`apiGetAircraft(accountId:${accountId}) url: ${apiURL}`);
  return getApiData(apiURL);
};

export const apiGetAircraftView = accountId => {
  // http://aircraftview.dashapi.dev.gogoair.com/v1/accounts/001F0000010HrxNIAS/aircraftView
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/aircraftView`;
  // logger.log(`apiGetAircraft(accountId:${accountId}) url: ${apiURL}`);
  return getApiData(apiURL);
};

export const apiForgotPassword = email => {
  const apiURL = `${apiUrl}/${API_VERSION}/forgotPassword`;
  return postPasswordResetApi(apiURL, { email });
};
