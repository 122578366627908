import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import { logout } from '../../actions';
import { setSearchFieldValue, clearSelectedAccount } from '../../actions/common';

class Logout extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.logout();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    logout: () => {
      localStorage.clear();
      sessionStorage.clear();

      dispatch(logout());

      history.push('/');
    },
  },
});

export default connect(null, mapDispatchToProps)(Logout);
