import logger from '../../../logger';
import { SET_NOTES, SET_NOTE, EDIT_NOTE, DELETE_NOTE } from './types';
import { SET_MESSAGE } from '../../../actions/types';

import {
  apiGetPresignedUrl,
  apiPutPresignedUrl,
  apiGetReleaseNotes,
  apiPutReleaseNote,
} from './api';

import ggDASHConfig from '../../../ggDASHConfig';

// get all notes
export const getNotes = accountId => async dispatch => {
  try {
    const url = await apiGetPresignedUrl(accountId);
    const data = await apiGetReleaseNotes(url);
    dispatch({ type: SET_NOTES, payload: data.results });
  } catch (ex) {
    logger.warn(`${ggDASHConfig.currentUser} action.getNotes ex: ${ex}`);
  }
};
// create note
export const setNote = (accountId, params) => async dispatch => {
  try {
    const putUrl = await apiPutPresignedUrl(accountId).then(async response => response);
    const getUrl = await apiGetPresignedUrl(accountId).then(async response => response);
    const data = await apiPutReleaseNote(putUrl, getUrl, params);
    dispatch({
      type: SET_MESSAGE,
      payload: {
        type: 'message',
        message: ['Release note successfully created.'],
        icon: 'fa-check',
      },
    });
    if (data === 200) {
      dispatch({ type: SET_NOTE, payload: params });
    } else {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: 'alert', message: data.status, icon: 'fa-times' },
      });
    }
  } catch (ex) {
    logger.warn(`${ggDASHConfig.currentUser} action.setNote ex: ${ex}`);
    dispatch({
      type: SET_MESSAGE,
      payload: { type: 'alert', message: ['Problem creating note'], icon: 'fa-times' },
    });
  }
};
// edit note
export const editNote = (accountId, newNote) => async dispatch => {
  try {
    const putUrl = await apiPutPresignedUrl(accountId).then(async response => response);
    const getUrl = await apiGetPresignedUrl(accountId).then(async response => response);
    const data = await apiGetReleaseNotes(getUrl);
    const filtered = data.results.map(n => {
      if (n.timestamp === newNote.timestamp) {
        return newNote;
      }
      return n;
    });
    apiPutReleaseNote(putUrl, getUrl, filtered);
    dispatch({
      type: SET_MESSAGE,
      payload: {
        type: 'message',
        message: ['Release note successfully updated.'],
        icon: 'fa-check',
      },
    });
    dispatch({ type: EDIT_NOTE, payload: { newNote } });
  } catch (ex) {
    logger.warn(`${ggDASHConfig.currentUser} action.editNote ex: ${ex}`);
  }
};
// delete note
export const deleteNote = (accountId, noteId, note) => async dispatch => {
  try {
    const putUrl = await apiPutPresignedUrl(accountId).then(async response => response);
    const getUrl = await apiGetPresignedUrl(accountId).then(async response => response);
    const data = await apiGetReleaseNotes(getUrl);
    const filtered = data.results.filter(n => n.timestamp !== note.timestamp);
    await apiPutReleaseNote(putUrl, getUrl, filtered);
    dispatch({
      type: SET_MESSAGE,
      payload: {
        type: 'message',
        message: ['Release note successfully deleted.'],
        icon: 'fa-check',
      },
    });
    dispatch({ type: DELETE_NOTE, payload: { noteId } });
  } catch (ex) {
    logger.warn(`${ggDASHConfig.currentUser} action.deleteNote ex: ${ex}`);
  }
};
