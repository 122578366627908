import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const isDateAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  const newDate = new Date().getTime();

  return newDate < expiresAt;
};

const ProtectedRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      // We're refreshing page, don't take us to login yet
      if (rest.isPageLoading) return null;

      if (rest.isAuthenticated && isDateAuthenticated()) {
        if (Component) {
          return <Component {...props} />;
        }
        return render();
      }
      // not authenticated. Save URL so they can be redirected to it later
      if (window.location.pathname) {
        localStorage.setItem('savedURL', window.location.pathname);
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default ProtectedRoute;
