import React from 'react';
import { connect } from 'react-redux';

import { resetPassword } from '../../../../actions';

const ForgotPassword = props => {
  const { username, handleUsernameChange, actions } = props;

  return (
    <div className="reset-password-form" data-test="reset-password-form">
      <input
        onChange={handleUsernameChange}
        type="text"
        value={username}
        placeholder="Email Address"
      />
      <p>Please enter your email address. We will send you an email to reset your password.</p>
      <button
        onClick={() => actions.resetPassword(username)}
        data-test="reset-password-button"
        type="submit"
      >
        Send Email
      </button>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: {
    resetPassword: user => dispatch(resetPassword(user)),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);
