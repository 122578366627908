import { connect } from 'react-redux';
import { getNotes, setNote, editNote, deleteNote } from './actions';
import Notes from './view';

/* istanbul ignore next */
const mapStateToProps = state => ({
  notes: state.global.notes,
  isInternal: state.global.userDetails.isInternal,
  accountId: state.global.selectedAccountId,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  actions: {
    getNotes: accountId => dispatch(getNotes(accountId)),
    createNote: (accountId, params) => dispatch(setNote(accountId, params)),
    editNote: (accountId, newNote) => dispatch(editNote(accountId, newNote)),
    deleteNote: (accountId, noteId, note) => dispatch(deleteNote(accountId, noteId, note)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
