// @TODO: shift this to a .env when we have the infra
// 'development' | 'stage' | 'production'
const getLDClientId = env => {
  if (env === 'stage') return '612691e160697a25d6f25c76';
  if (env === 'development') return '6126ad99d26e7d25d9e41abb';
  if (env === 'production') return '612691e160697a25d6f25c77';
  // Return development by default
  return '6126ad99d26e7d25d9e41abb';
};

export default getLDClientId;
