import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import classnames from 'classnames';
import validate from './validate';

const renderField = ({ className, input, label, type, placeholder, meta: { touched, error } }) => (
  <div className="field">
    <div className="field-header">
      <label>{label}</label>
      {touched && error && (
        <span className={`${className} error`} data-test={`${className} error`}>
          {error}
        </span>
      )}
    </div>
    <input
      className={className}
      data-test={className}
      {...input}
      placeholder={placeholder}
      type={type}
    />
  </div>
);

const renderTextArea = ({
  className,
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
}) => (
  <div className="field">
    <div className="field-header">
      <label>{label}</label>
      {touched && error && (
        <span className={`${className} error`} data-test={`${className} error`}>
          {error}
        </span>
      )}
    </div>
    <textarea
      className={className}
      data-test={className}
      {...input}
      placeholder={placeholder}
      type={type}
    />
  </div>
);

const NewNoteForm = ({ handleSubmit, invalid, submitting, hideForm, pristine }) => (
  <div className="new-note" data-test="new-note">
    <div className="header">
      <h2>Create a New Release Note</h2>
    </div>
    <form className="note-form" onSubmit={handleSubmit}>
      <div className="form-section">
        <Field
          name="title"
          className="title"
          type="text"
          component={renderField}
          label="TITLE"
          placeholder="Release v1.3.4 notes"
        />
      </div>
      <div className="form-section">
        <Field
          name="body"
          className="body"
          type="text"
          component={renderTextArea}
          label="BODY"
          placeholder="In this release we added new functionality for our users..."
        />
      </div>
      <div className="save-note">
        <h5>Save Note?</h5>
        <button type="submit" disabled={submitting || pristine || invalid}>
          <i className="fas fa-check" />
        </button>
        <button type="button" onClick={hideForm}>
          <i className="fas fa-ban" />
        </button>
      </div>
    </form>
  </div>
);

export default reduxForm({
  form: 'newNoteForm',
  validate,
})(NewNoteForm);
