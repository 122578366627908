import {
  DELETE_MESSAGE,
  SET_MESSAGE,
} from './types';

// ---------------- clear messages

export const clearMessage = () => ({ type: DELETE_MESSAGE });

// ---------------- set messages

export const setMessage = value => ({ type: SET_MESSAGE, payload: value });
