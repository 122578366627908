// auth
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// error / general messages
export const SET_MESSAGE = 'SET_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_ALL_MESSAGES = 'DELETE_ALL_MESSAGES';

// global
export const PAGE_REFRESH_LOADING = 'PAGE_REFRESH_LOADING';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export const SET_SELECTED_AIRCRAFT = 'SET_SELECTED_AIRCRAFT';

export const SET_SEARCH_FIELD_VALUE = 'SET_SEARCH_FIELD_VALUE';

export const SET_AIRCRAFT = 'SET_AIRCRAFT';

// release notes
export const SET_NOTES = 'SET_NOTES';
export const SET_NOTE = 'SET_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

// loading
export const LOADING_UI = 'LOADING_UI';
