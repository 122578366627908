import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from '@gogoair/ba-ui-kit';

import DashLogo from '../../assets/DASHLogo';
import HomeLogo from '../../assets/HomeLogo';
import HelpIcon from './assets/helpIcon';

import './index.css';

const clickableLogo = (
  <Link href="#/" to="/">
    {DashLogo}
  </Link>
);

const View = ({ isAuthenticated, userDetails, apps, location }) => {
  if (location?.pathname?.includes('customer-onboarding')) return null;

  return (
    <Navbar brand={clickableLogo}>
      {isAuthenticated && apps && location && location.pathname !== '/login' ? (
        <React.Fragment></React.Fragment>
      ) : (
        <Navbar.Link url="/login" label="LOG IN" name="login" />
      )}
      {location && location.pathname !== '/SSO' && (
        <Navbar.Link url="/" icon={HomeLogo} name="home" position="right" label="" />
      )}
      {userDetails.name && (
        <Navbar.Dropdown label={userDetails.name} name="profile-options" position="right">
          <Navbar.Link url="/logout" label="SIGN OUT" name="logout" />
        </Navbar.Dropdown>
      )}
      <Navbar.Link url="/help" icon={HelpIcon} name="help" position="right" />
    </Navbar>
  );
};

export default View;
