export const REQUIRED = 'Required';
export const INVALID_NAME = 'A-Z, 0-9, hyphens only, and length greater than 1.';

export const isValidEntry = entry => /^[A-Z0-9._%+-][A-Z0-9.-]/i.test(entry);

export const inputValidate = values => {
  const errors = {};

  if (!isValidEntry(values.title)) {
    errors.title = INVALID_NAME;
  }
  return errors;
};

export const textboxValidate = values => {
  const errors = {};

  if (!isValidEntry(values.body)) {
    errors.body = INVALID_NAME;
  }
  return errors;
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = REQUIRED;
  }

  if (!isValidEntry(values.title)) {
    errors.title = INVALID_NAME;
  }

  if (!values.body) {
    errors.body = REQUIRED;
  }

  if (!isValidEntry(values.body)) {
    errors.body = INVALID_NAME;
  }

  return errors;
};

export default validate;
