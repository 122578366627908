/* istanbul ignore file */
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { RestLink } from 'apollo-link-rest';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import history from './history';
import checkForAuthenticatedUser from './lib/checkForAuthenticatedUser';
import getBearerToken from './lib/getBearerToken';
import store from './store';
import './styles/reset.css';
import './styles/shared.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

checkForAuthenticatedUser(store);

const restLink = new RestLink({
  uri: '/',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getBearerToken().headers,
      'Content-Type': 'application/json',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(restLink),
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

const Root = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <Route path="/" render={props => <App {...props} history={history} client={client} />} />
        </Router>
      </QueryClientProvider>
    </Provider>
  </ApolloProvider>
);

// @NOTE: used for QA processes
// eslint-disable-next-line no-console
// console.info(
//   `%c
//   ✈✈✈
//   Current version: ${process.env.REACT_APP_VERSION}
//   ✈✈✈
// `,
//   'font-size: 16px'
// );
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
