import React from 'react';
import { connect } from 'react-redux';
import * as dashActions from '../../../../actions';

class ActionsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mode: 'default' };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.editNote = this.editNote.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
  }

  handleEditClick(e) {
    e.stopPropagation();
    const { setEditMode } = this.props;
    this.setState({ mode: 'edit' });
    setEditMode();
  }

  handleDeleteClick(e) {
    const { setEditMode } = this.props;
    e.stopPropagation();

    this.setState({ mode: 'delete' });
    setEditMode();
  }

  cancelEdit() {
    const { setEditMode, cancelEdit } = this.props;
    this.setState({ mode: 'default' });
    cancelEdit();
    setEditMode();
  }

  editNote() {
    const { accountId, note, title, body, actions, setEditMode } = this.props;
    this.setState({ mode: 'default' });
    // If the note didn't change the name but hits save, don't bother
    const newNote = {
      title,
      body,
      timestamp: note.timestamp,
    };

    if (note.title !== title.trim() || note.body !== body.trim()) {
      actions.editNote(accountId, newNote);
    }
    setEditMode();
  }

  deleteNote() {
    const { note, actions, accountId } = this.props;
    actions.deleteNote(accountId, note.timestamp, note);
  }

  render() {
    const { mode } = this.state;

    const { isDisabled } = this.props;
    switch (mode) {
      case 'edit': {
        return (
          <div className="note-actions edit" data-test="note-actions edit">
            <span className="edit-container">
              <h5>Save Note?</h5>
              <button type="button" data-test="save" onClick={this.editNote} disabled={isDisabled}>
                <i className="fas fa-check" />
              </button>
              <button
                type="button"
                className="cancel"
                data-test="cancel-save"
                onClick={this.cancelEdit}
              >
                <i className="fas fa-ban" />
              </button>
            </span>
          </div>
        );
      }

      case 'delete': {
        return (
          <div className="note-actions delete" data-test="note-actions delete">
            <span className="delete-container">
              <h5>Delete Note?</h5>
              <button
                type="button"
                className="confirm-delete"
                data-test="confirm-delete"
                onClick={this.deleteNote}
                disabled={isDisabled}
              >
                <i className="fas fa-check" />
              </button>
              <button type="button" className="cancel" data-test="delete" onClick={this.cancelEdit}>
                <i className="fas fa-ban" />
              </button>
            </span>
          </div>
        );
      }

      default: {
        return (
          <div className="note-actions" data-test="note-actions">
            <button type="button" data-test="delete-note" onClick={this.handleDeleteClick}>
              <i className="fas fa-trash" />
            </button>
            <button
              type="button"
              className="edit-note"
              data-test="edit-note"
              onClick={this.handleEditClick}
            >
              <i className="fas fa-pencil-alt" />
            </button>
          </div>
        );
      }
    }
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  const results = {
    accountId: state.global.selectedAccountId,
  };
  return results;
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  actions: {
    deleteNote: (accountId, noteId, note) =>
      dispatch(dashActions.deleteNote(accountId, noteId, note)),
    editNote: (accountId, noteId, title, body) =>
      dispatch(dashActions.editNote(accountId, noteId, title, body)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsGroup);
