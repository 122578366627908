import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ResetForm from './components/ResetForm';
import { logout } from '../../actions';
import { setSearchFieldValue, clearSelectedAccount } from '../../actions/common';
import './index.css';
import logger from '../../logger';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { actions, isAuthenticated } = this.props;
    if (isAuthenticated) {
      actions.logout();
    }

    function getUrlVars() {
      var vars = {};
      var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
      });
      return vars;
    }

    const ticket = getUrlVars('ticket');

    const { history } = this.props;

    if (!ticket.ticket) {
      history.push('/login');
    }
  }

  componentDidUpdate() {
    const { actions, isAuthenticated } = this.props;

    if (isAuthenticated) {
      actions.logout();
    }
  }

  handleSubmit({ password }) {
    const {
      match: {
        params: { userID },
      },
      history,
    } = this.props;

    function getUrlVars() {
      var vars = {};
      var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
      });
      return vars;
    }

    const ticket = getUrlVars('ticket');

    fetch(`/api/v1/changePassword/${userID}?ticket=${ticket.ticket}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
      .then(async response => {
        if (response.status !== 200) {
          let err;

          try {
            err = await response.json();
          } catch (_) {
            err = await response.text();
          }

          // console.error(err);
          switch (response.status) {
            case 400:
            case 404:
              return this.setState({ error: 'Invalid password reset request.' });
            default:
              return this.setState({ error: 'Unable to process your reset request.' });
          }
        }
        return response;
      })
      .then(() => history.push('/login'))
      .catch(err => {
        // console.error(err);
        return this.setState({ error: 'Unable to process your reset request.' });
      });
  }

  render() {
    const { error } = this.state;
    return (
      <section className="reset-password-container">
        <ResetForm error={error} handleSubmit={this.handleSubmit} />
      </section>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    isAuthenticated: state.global.isAuthenticated,
  }),
  dispatch => ({
    actions: {
      logout: () => {
        localStorage.clear();
        sessionStorage.clear();

        dispatch(logout());

        dispatch(setSearchFieldValue());
        dispatch(clearSelectedAccount());
      },
    },
  })
)(withRouter(ResetPassword));
