import { connect } from 'react-redux';

import View from './view';

// connect App to redux for profile/account info
const mapStateToProps = state => ({
  isAuthenticated: state.global.isAuthenticated,
  selectedAccountId: state.global.selectedAccountId,
  isInternal: state.global.userDetails.isInternal,
});

export default connect(mapStateToProps)(View);
