/* eslint jsx-a11y/tabindex-no-positive: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

const LoginForm = props => {
  const { handleSubmit, handleUsernameChange, formSubmitting } = props;

  return (
    <form className="login-form" data-test="login-form" onSubmit={handleSubmit}>
      <Field
        onChange={handleUsernameChange}
        data-test="username"
        name="username"
        component="input"
        type="email"
        placeholder="Username"
        tabIndex="1"
      />
      <Field
        data-test="password"
        name="password"
        component="input"
        type="password"
        placeholder="Password"
        tabIndex="2"
      />
      <Link
        className="forgot-password-link"
        data-test="forgot-password-link"
        to="/forgotpassword"
        tabIndex="4"
      >
        Forgot Password?
      </Link>
      <aside>
        Login Confirms Acceptance of:{' '}
        <a
          className="privacy-policy"
          data-test="privacy-policy"
          href="https://business.gogoair.com/policies/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </aside>
      <button data-test="sign-in-button" type="submit" disabled={formSubmitting} tabIndex="3">
        {formSubmitting ? 'Processing...' : 'Sign In'}
      </button>
    </form>
  );
};

export default reduxForm({ form: 'login' })(LoginForm);
