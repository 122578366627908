import { connect } from 'react-redux';
import { loginAs } from '../../actions';
import View from './view';

const mapStateToProps = state => ({
  isAuthenticated: state.global.isAuthenticated,
  apps: state.global.userDetails.apps,
  accountId: state.global.selectedAccountId,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    loginAs: (name, accountName, email, cob_unverified) => {
      dispatch(loginAs(name, accountName, email, cob_unverified));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
