import React from 'react';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

const ResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Confirm password is required'),
});

const ResetForm = props => (
  <div className="inner-reset-password">
    <h1>Reset Password</h1>
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={(values, actions) => props.handleSubmit(values)}
      validate={values => {
        const errors = {};

        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords must match';
        }

        return errors;
      }}
      validationSchema={ResetSchema}
    >
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <div className="reset-password-inputs">
            <Field type="password" placeholder="Password" name="password" />
            <Field type="password" placeholder="Confirm password" name="confirmPassword" />
          </div>
          {!!touched.confirmPassword && !!errors.confirmPassword && (
            <p style={{ margin: '0.5rem 0 0', flex: '1' }}>{errors.confirmPassword}</p>
          )}
          <button type="submit">Reset Password</button>
        </form>
      )}
    </Formik>
  </div>
);

ResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ResetForm;
