/* eslint-disable no-console */
import Rollbar from 'rollbar';
import ggDASHConfig from './ggDASHConfig';

const env = ggDASHConfig.get('deployedEnv');

const rollbarConfig = {
  //  TODO: convert this token to a more general Gogo DASH account token.
  accessToken: 'b73117e673074dcd886a1bf7277a170f',
  captureUncaught: true,
  captureUnhadledRejections: true,
  payload: {
    environment: env,
  },
};

//  Outer object we attach custom handlers to below.
const logger = {};

//  Turn on rollbar for prod only.
if (env === 'production') {
  const rollbar = new Rollbar(rollbarConfig);

  //  In production we want the majority of console calls to be
  //  silenced (console.log/info/debug/trace/group/etc.).
  Object.keys(console).forEach(key => {
    const slot = console[key];
    if (typeof slot === 'function') {
      //  NOTE these are essentially no-op functions to silence
      //  normal development log messages etc.
      logger[key] = () => {};
    }
  });

  logger.error = (...args) => {
    console.error(...args);
    //  Errors should log and invoke Rollbar api.
    rollbar.error(...args);
  };
} else {
  //  In non-production environments we want logger.* to map to
  //  console.* without any extra overhead or filtering.
  Object.keys(console).forEach(key => {
    const slot = console[key];
    if (typeof slot === 'function') {
      //  NOTE these are effectively simple forwarding wrappers
      //  so invoking logger.* just calls console.*.
      logger[key] = (...args) => {
        console[key](...args);
      };
    }
  });
}

export default logger;
