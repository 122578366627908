/* global fetch */
import 'whatwg-fetch';
import { getS3PresignedUrls, getReleaseNotesFromS3, putReleaseNoteInS3 } from '../../../lib/restUtils';

const apiUrl = '/api';
// const API_VERSION = 'v2'; // for bender
const API_VERSION = 'v1'; // for dev connectivity

export const apiGetPresignedUrl = (accountId) => {
  // http://dashcommons.dashapi.dev.gogoair.com/v1/accounts/<accountId>/releaseNotesGETUrl
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/releaseNotesGETUrl`;
  return getS3PresignedUrls(apiURL);
};

export const apiPutPresignedUrl = (accountId) => {
  // http://dashcommons.dashapi.dev.gogoair.com/v1/accounts/<accountId>/releaseNotesPUTUrl
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/releaseNotesPUTUrl`;
  return getS3PresignedUrls(apiURL);
};

export const apiGetReleaseNotes = url => getReleaseNotesFromS3(url);

export const apiPutReleaseNote = (putUrl, getUrl, params) => putReleaseNoteInS3(putUrl, getUrl, params);
