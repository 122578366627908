import moment from 'moment-timezone';
import ggDASHConfig from '../ggDASHConfig';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// prefix number with a zero if less than 10. useful for time formatting
export const addZ = n => `${n}`.padStart(2, '0');

//  ---
//  UTC
//  ---

export const utcTimeFromDate = (epochTime) => {
  const d = new Date(epochTime);
  return `${addZ(d.getUTCHours())}:${addZ(d.getUTCMinutes())}:${addZ(d.getUTCSeconds())}`;
};

export const utcDate = (epochTime) => {
  const d = new Date(epochTime);
  return `${months[d.getUTCMonth()]} ${addZ(d.getUTCDate())}`;
};

export const utcDateTime = epochTime => `${utcDate(epochTime)} - ${utcTimeFromDate(epochTime)}`;

export const utcDateToMomentDate = (date) => {
  const newDate = moment.utc(date);
  return `${newDate.format('ddd Do hh:mm A')}`;
};

export const utcToLocalDateAndTime = (date, timeZone) => {
  let newDate;

  if (timeZone) {
    newDate = moment.utc(date).tz(timeZone);
  }
  else {
    newDate = moment.utc(date).local();
  }

  return `${newDate.format('MMM Do h:mm A')}`;
};

export const utcToLocalDateAndTimeAndTimezone = (date, timeZone) => {
  let newDate;
  let newTimeZone = timeZone;

  if (newTimeZone) {
    newDate = moment(date).tz(timeZone);
  }
  else {
    newDate = moment(date).local();
    newTimeZone = moment.tz.guess(newDate);
  }

  return `${newDate.format('MMM Do - h:mm A')} ${newTimeZone}`;
};

export const utcToLocalTime = (date, timeZone) => {
  let newDate;

  if (timeZone) {
    newDate = moment.utc(date).tz(timeZone);
  }
  else {
    newDate = moment.utc(date).local();
  }
  return `${newDate.format('h:mm A')}`;
};

export const utcToDayMonthYear = (date, timezone) => {
  let newDate;
  let newTimeZone = timezone;
  if (newTimeZone) {
    newDate = moment(date).tz(timezone);
  }
  else {
    newDate = moment(date).local();
    newTimeZone = moment.tz.guess(newDate);
  }
  return `${newDate.format('MM/DD/YYYY')}`;
};

//  ---
//  USER (profile-driven)
//  ---

export const userDateAndTime = (date, timeZone) => {
  if (ggDASHConfig.get('utcDates') === true) {
    const newDate = moment.utc(date);
    return `${newDate.format('MMM Do h:mm A')}`;
  }
  return utcToLocalDateAndTime(date, timeZone);
};

export const userDateAndTimeAndTimezone = (date, timeZone) => {
  if (ggDASHConfig.get('utcDates') === true) {
    const newDate = moment.utc(date);
    return `${newDate.format('MMM Do - h:mm A')} UTC`;
  }
  return utcToLocalDateAndTimeAndTimezone(date, timeZone);
};
