import { connect } from 'react-redux';
import View from './view';

const mapStateToProps = state => ({
  isAuthenticated: state.global.isAuthenticated,
  userDetails: state.global.userDetails,
  apps: state.global.userDetails.apps,
});

export default connect(mapStateToProps)(View);
