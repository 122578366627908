export class ReducerRegistry {
  constructor() {
    this.emitChange = null;
    this.reducers = {};
  }

  getReducers() {
    return { ...this.reducers };
  }

  register(name, reducer) {
    this.reducers = { ...this.reducers, [name]: reducer };
    return new Promise((resolve, reject) => {
      if (this.emitChange) {
        const store = this.emitChange(this.getReducers());
        resolve({ store });
      } else {
        resolve({});
      }
    });
  }

  setChangeListener(listener) {
    this.emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
