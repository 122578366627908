import React from 'react';

export default class MessageBanner extends React.Component {
  render() {
    const { actions, message } = this.props;
    const messageType = message ? message.type : null;
    const messageIcon = message ? message.icon : null;
    const messages = message ? message.message : null;
    return (
      <div>
        {messages &&
          messages.map(m => (
            <div key={m} className={messageType} data-test={messageType}>
              <button type="button" onClick={actions.messageClickHandler}>
                <i className={`fas ${messageIcon}`} />
                {m}
                <i className="far fa-times-circle" />
              </button>
            </div>
          ))}
      </div>
    );
  }
}
