import React from 'react';
import ActionsGroup from './components/ActionsGroup';
import { utcToDayMonthYear } from '../../../../lib/timeUtils';
import isValid from '../../../../lib/isValid';
import { inputValidate, textboxValidate } from '../NewNoteForm/validate';

const stopPropagation = e => e.stopPropagation();

const NotesTableRows = ({ notes, isInternal }) => {
  let result;
  if (isValid(notes) && notes.length > 0) {
    result = (
      <div className="note-container">
        {notes.map(note => (
          <NotesTable note={note} isInternal={isInternal} key={note.timestamp.timestamp} />
        ))}
      </div>
    );
  } else {
    result = null;
  }
  return result;
};

class NotesTable extends React.Component {
  constructor(props) {
    super(props);
    this.setEditMode = this.setEditMode.bind(this);
    this.toggleActions = this.toggleActions.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);

    const { note } = this.props;
    this.state = {
      isNoteEditable: false,
      title: note.title,
      body: note.body,
      inputError: null,
      textareaError: null,
      isDisabled: false,
    };
  }

  setEditMode() {
    const { isNoteEditable } = this.state;
    if (isNoteEditable) {
      this.setState({ isNoteEditable: false });
    } else {
      this.setState({ isNoteEditable: true });
    }
  }

  toggleActions() {
    const { note } = this.props;
    this.setState({
      isNoteEditable: false,
      title: note.title,
      body: note.body,
    });
  }

  cancelEdit() {
    const { note } = this.props;

    this.setState({
      isNoteEditable: false,
      title: note.title,
      body: note.body,
    });
  }

  handleInputChange(e) {
    const { textareaError } = this.state;
    const input = { title: e.target.value };
    const inputError = inputValidate(input);
    const validInput = inputError && isValid(inputError.title) ? inputError.title : null;
    const validTextarea = textareaError && isValid(textareaError.body) ? textareaError.body : null;
    if (
      (validInput && isValid(inputError.title)) ||
      (validTextarea && isValid(textareaError.body))
    ) {
      this.setState({
        title: e.target.value,
        inputError,
        isDisabled: true,
      });
    } else {
      this.setState({
        title: e.target.value,
        inputError,
        isDisabled: false,
      });
    }
  }

  handleTextareaChange(e) {
    const { inputError } = this.state;
    const input = { body: e.target.value };
    const textareaError = textboxValidate(input);
    if (isValid(textareaError) && textareaError.body) {
      this.setState({
        body: e.target.value,
        textareaError,
        isDisabled: true,
      });
    } else if (isValid(inputError) && inputError.title) {
      this.setState({
        body: e.target.value,
        textareaError,
        isDisabled: true,
      });
    } else {
      this.setState({
        body: e.target.value,
        textareaError,
        isDisabled: false,
      });
    }
  }

  render() {
    const { note, isInternal } = this.props;
    const { isNoteEditable, title, body, inputError, textareaError, isDisabled } = this.state;
    return (
      <div key={note.timestamp} className="note">
        {isNoteEditable ? (
          <div key={note.timestamp} className="note-title-and-body">
            <span className="note-error">{inputError ? inputError.title : null}</span>
            <input value={title} onClick={stopPropagation} onChange={this.handleInputChange} />
            <span className="note-error">{textareaError ? textareaError.body : null}</span>
            <textarea value={body} onClick={stopPropagation} onChange={this.handleTextareaChange} />
          </div>
        ) : (
          <div key={note.timestamp} className="note-title-and-body">
            <h1>
              {title}
              <span className="note-timestamp">{utcToDayMonthYear(note.timestamp)}</span>
            </h1>
            <p>{body}</p>
          </div>
        )}
        {isInternal ? (
          <ActionsGroup
            note={note}
            title={title}
            body={body}
            inputError={inputError}
            textareaError={textareaError}
            isDisabled={isDisabled}
            setEditMode={this.setEditMode}
            cancelEdit={this.cancelEdit}
          />
        ) : null}
      </div>
    );
  }
}

export default NotesTableRows;
